import request from '@/utils/request'

//获取积分配置
export function getPoint(params) {
    return request({
        url: '/admin/getPoint',
        method: 'GET',
        params
    })
}

//修改积分配置
export function savePoint(data) {
    return request({
        url: '/admin/savePoint',
        method: 'POST',
        data
    })
}