<template>
  <div>
    <div class="title">
      <span>通用积分规则</span>
    </div>
    <div
      style="margin:40px 0;width:100%;display: flex;flex-direction: column;align-items: center;"
    >
      <div
        style="width:1200px;display: flex;justify-content: flex-end;margin-bottom:30px"
        v-if="operateList.indexOf('update') > -1"
      >
        <el-button type="primary" size="mini" @click="addPoint('up')"
          >编辑</el-button
        >
      </div>
      <el-table
        v-loading="listLoading"
        :data="infoTbale"
        style="width:1200px"
        border
        size="mini"
        :header-cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column
          prop="name"
          label="规则名称"
          width="auto"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="label"
          label="规则详情"
          width="auto"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="updated_at"
          width="auto"
          label="更新时间"
          align="center"
        >
        </el-table-column>
      </el-table>
    </div>
    <div class="title">
      <span>积分消耗规则</span>
    </div>
    <div
      style="margin:30px 0;width:100%;display: flex;flex-direction: column;align-items: center;"
    >
      <div
        style="width:1200px;display: flex;justify-content: flex-end;margin-bottom:30px"
        v-if="operateList.indexOf('update') > -1"
      >
        <el-button type="primary" size="mini" @click="addPoint('down')"
          >编辑</el-button
        >
      </div>
      <el-table
        v-loading="listLoading"
        :data="consumeTable"
        style="width:1200px"
        border
        size="mini"
        :header-cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column
          prop="name"
          label="规则名称"
          width="auto"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="label"
          label="规则详情"
          width="auto"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="updated_at"
          width="auto"
          label="更新时间"
          align="center"
        >
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="30%" :show-close="false">
      <div>
        <div style="margin-bottom:20px">
          <span style="font-size:22px;border-bottom: #2362FB 3px solid;"
            >编辑通用积分规格</span
          >
        </div>
      </div>
      <div style="display: flex;margin-bottom:15%;" v-if="this.type == 'up'">
        <span style="margin-right:20px;margin-bottom:20px;"
          ><span style="color:red">*</span>积分有效期</span
        >
        <div style="display: flex;flex-direction: column;">
          <el-radio
            v-model="info.expired_type"
            :label="1"
            style="margin-bottom:15px;"
            >永久有效</el-radio
          >
          <el-radio v-model="info.expired_type" :label="2"
            >获得后<el-input
              size="mini"
              v-model="info.expired_day"
              class="input-radio"
            ></el-input
            >天有效</el-radio
          >
        </div>
      </div>
      <div style="display: flex;margin-bottom:15%;" v-if="this.type == 'up'">
        <span style="margin-right:20px;margin-bottom:20px;"
          ><span style="color:red">*</span>消费积分获取上线</span
        >
        <div style="display: flex;flex-direction: column;">
          <el-radio
            v-model="info.limit_type"
            :label="1"
            style="margin-bottom:15px;"
            >无上限</el-radio
          >
          <el-radio v-model="info.limit_type" :label="2"
            >每天可获得<el-input
              size="mini"
              v-model="info.point"
              class="input-radio"
            ></el-input
            >积分</el-radio
          >
        </div>
      </div>
      <div style="display: flex;margin-bottom:20px;" v-if="this.type == 'down'">
        <span style="margin-right:20px;margin-bottom:20px;"
          ><span style="color:red">*</span>抵现比例</span
        >
        <div style="display: flex;align-items: center;">
          <el-input
            size="mini"
            v-model="info.deduct_point"
            class="input-radio"
          ></el-input
          >积分=1元
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="_ok" size="mini">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getPoint, savePoint } from "@/newApi/integral.js";
export default {
  data() {
    return {
      operateList: [],
      dialogVisible: false,
      info: {},
      infoTbale: [],
      consumeTable: [],
      type: "", //up是通用积分规则，down是积分消耗规则
      listLoading: false,
    };
  },
  created() {
    this._getPoint();
  },
  mounted() {
        this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      console.log(res)
      this.operateList = res;
    });
  },
  methods: {
    _ok() {
      savePoint(this.info).then((res) => {
        this.$message({
          type: "success",
          message: "保存成功",
        });
        this.dialogVisible = false;
        this._getPoint();
      });
    },
    addPoint(type) {
      console.log(type);
      this.type = type;
      this.dialogVisible = true;
    },
    _getPoint() {
      this.listLoading = true;
      getPoint().then((res) => {
        this.info = res.data;
        let time =
          this.info.expired_type == 1
            ? "永久有效"
            : "获得后" + this.info.expired_day + "天有效";
        let up =
          this.info.limit_type == 1
            ? "无上限"
            : "每天克获得" + this.info.point + "积分";
        let times = this.info.updated_at
          ? this.info.updated_at
          : this.info.created_at;

        this.infoTbale = [
          {
            name: `积分有效期`,
            label: time,
            updated_at: times,
            created_at: this.info.created_at,
          },
          {
            name: `消耗积分发放`,
            label: "确认收货后",
            updated_at: times,
            created_at: this.info.created_at,
          },
          {
            name: `积分获取上限`,
            label: up,
            updated_at: times,
            created_at: this.info.created_at,
          },
          {
            name: `积分兑换比例`,
            label: "1元=" + this.info.deduct_point + "积分",
            updated_at: times,
            created_at: this.info.created_at,
          },
        ];
        this.consumeTable = [
          {
            name: "积分抵现比例",
            label: this.info.deduct_point + ": 1",
            updated_at: times,
            created_at: this.info.created_at,
          },
        ];
        this.listLoading = false;
      });
    },
    savePoint() {
      savePoint(this.info).then((res) => {
        this.$message.success("保存成功");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  padding: 7px 20px;
  box-sizing: border-box;
  border-radius: 10px;
  background: #efefef;
}
.input-radio {
  width: 100px;
  margin: 0 10px;
}
</style>
